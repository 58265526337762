<template>
  <div class="container_notifications">
    <b-container class="py-4 py-lg-5 px-md-5">
      <!-- Notifications -->
      <b-row class="mb-lg-5 pt-lg-4">
        <b-col cols="12" class="mb-lg-3">
          <h3 class="fw-bold">Notifications</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </b-col>
        <b-col cols="12" align-self="center">

          <b-card no-body class="py-4 px-2 px-md-4 mb-4">
            <b-container class="px-0">
              <b-row>
                <b-col cols="3" md="2" lg="2" xl="1" class="text-center notification-icon" align-self="center">
                  <b-avatar :src="require('@/assets/img/phone.png')" size="50" class="bg-danger" badge badge-top></b-avatar>
                </b-col>
                <b-col align-self="center" class="notification-text">
                  <p class="mb-0">
                    <label class="fw-bold">Lorem Ipsum is simply dummy</label>&nbsp;&nbsp;
                    Text of the printing and typesetting industry. Lorem Ipsum has been
                  </p>
                </b-col>
                <b-col cols="12" lg="3" xl="2" class="text-center pt-3 py-lg-2 notification-actions" align-self="center">
                  <img src="@/assets/img/no-bell.png" width="30" height="30" class="me-3">
                  <img src="@/assets/img/trash.png" width="30" height="30">
                </b-col>
              </b-row>
            </b-container>
          </b-card>

          <b-card no-body class="py-4 px-2 px-md-4 mb-4">
            <b-container class="px-0">
              <b-row>
                <b-col cols="3" md="2" lg="2" xl="1" class="text-center notification-icon" align-self="center">
                  <b-avatar :src="require('@/assets/img/pen.png')" size="50" class="bg-warning" badge badge-top></b-avatar>
                </b-col>
                <b-col align-self="center" class="notification-text">
                  <p class="mb-0">
                    <label class="fw-bold">Lorem Ipsum is simply dummy</label>&nbsp;&nbsp;
                    Text of the printing and typesetting industry. Lorem Ipsum has been
                  </p>
                </b-col>
                <b-col cols="12" lg="3" xl="2" class="text-center pt-3 py-lg-2 notification-actions" align-self="center">
                  <img src="@/assets/img/no-bell.png" width="30" height="30" class="me-3">
                  <img src="@/assets/img/trash.png" width="30" height="30">
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>

      <!-- Messages -->
      <b-row class="mb-lg-3 pt-lg-4">
        <b-col cols="12" class="mb-lg-3">
          <h3 class="fw-bold">Message</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </b-col>
        <b-col cols="12" align-self="center">

          <b-card no-body class="py-4 px-4 mb-4">
            <b-container class="px-0">
              <b-row>
                <b-col cols="3" md="2" lg="2" xl="1" class="text-center message-icon active" align-self="center">
                  <b-avatar :src="require('@/assets/img/messages.png')" size="60" badge badge-top></b-avatar>
                </b-col>
                <b-col>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting, remaining
                    essentially unchanged
                  </p>
                </b-col>
                <b-col lg="3" xl="2" class="text-center pt-4 pt-lg-0" align-self="center">
                  <b-container class="p-0">
                    <b-row class="p-0 message-actions">
                      <b-col cols="6" md="3" lg="12" class="py-lg-2" order="2" order-lg="1">
                        <img src="@/assets/img/no-bell.png" width="30" height="30" class="me-3">
                        <img src="@/assets/img/trash.png" width="30" height="30">
                      </b-col>
                      <b-col cols="6" md="3" lg="12" class="py-lg-2" order="1" order-lg="2">
                        <b-button pill variant="outline-primary">Go to panel</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card>

          <b-card no-body class="py-4 px-4 mb-4">
            <b-container class="px-0">
              <b-row>
                <b-col cols="3" md="2" lg="2" xl="1" class="text-center message-icon" align-self="center">
                  <b-avatar :src="require('@/assets/img/messages.png')" size="60" badge badge-top></b-avatar>
                </b-col>
                <b-col>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting, remaining
                    essentially unchanged
                  </p>
                </b-col>
                <b-col lg="3" xl="2" class="text-center pt-4 pt-lg-0" align-self="center">
                  <b-container class="p-0">
                    <b-row class="p-0 message-actions">
                      <b-col cols="6" md="3" lg="12" class="py-lg-2" order="2" order-lg="1">
                        <img src="@/assets/img/no-bell.png" width="30" height="30" class="me-3">
                        <img src="@/assets/img/trash.png" width="30" height="30">
                      </b-col>
                      <b-col cols="6" md="3" lg="12" class="py-lg-2" order="1" order-lg="2">
                        <b-button pill variant="outline-primary">Go to panel</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
